body {
  margin: 0;
  background-color: #f3f3f4;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-print-color-adjust:exact;
  -moz-print-color-adjust:exact;
  -ms-print-color-adjust:exact;
  font-size: 14px !important;
}

a{
  text-decoration: none;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sider-bar {
  background-color: #2f4050;
}

/* 登录页面 */
.center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.hidden {
  visibility: hidden;
}

.dropdown-item, .form-control {
  font-size: 14px !important
}


/* mod material ui css*/
.MuiDrawer-paper{
  background-color: #f3f3f4 !important;
  padding: 10px !important;
}


